@import '../../shared//styles/index.scss';

.container {
    @extend %flex-center;

    padding: 4px 12px;
    border-radius: 8px;
    min-width: 116px;
    width: fit-content;
    max-width: 300px;
    text-align: center;
    font-weight: 600;
    cursor: pointer;
}

.gradient {
    font-size: 14px;
    padding: 4px 4px 8px;
    background: $darkBlue;
}
