@import 'src/shared/styles/index.scss';

.overlay {
    @extend %full-page-fixed;

    background: rgba($black, 0.7);
    z-index: $max-zindex;
}

.container {
    position: absolute;
    background: $white;
    width: 50%;
    min-width: 640px;
    right: 0px;
    height: 100%;
    overflow-y: scroll;

    &Heading {
        position: sticky;
        top: 0px;
        background: $white;
    }
}

.close {
    @extend %flex-center;

    position: absolute;
    right: 16px;
    background: $black;
    padding: 4px;

    &Icon {
        color: $white;
        min-width: 12px;
        width: 12px;
        height: 12px;
    }
}

.loader {
    @extend %flex-center;

    position: relative;
    top: 32%;
}

.left {
    width: 180px;
    min-width: 180px;

    &Full {
        border-right: none;
        width: 100%;
    }
}

.right {
    flex: 1;
}

.typewriter {
    position: absolute;
    top: 110%;
    left: 35%;
    overflow: hidden; /* Ensures the content is not revealed until the animation */
    white-space: nowrap; /* Keeps the content on a single line */
    animation: typing 3.5s infinite;
}

/* The typing effect */
@keyframes typing {
    from,
    to {
        width: 132px;
    }
    to {
        width: 190px;
    }
}
