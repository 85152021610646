@import '../../shared/styles/index.scss';

.section {
    position: relative;

    &Container {
        @extend %flex-center-vertical;
    }
}

.connector {
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 0px;
    right: 0px;
    background: $grey-80;
    height: 2px;
    border-radius: 2px;
    box-shadow: 0px 0px 12px 0px $grey-90;
}

.circle {
    @extend %flex-center;

    box-shadow: 0px 0px 8px 2px $grey-50;
    z-index: 2;
    background: $white;
    margin-left: calc(60% / 3);
    height: 60px;
    width: 60px;
    border-radius: 100%;
    border: 2px solid $grey-90;

    &Icon {
        width: 40px;
        height: 40px;

        &Img {
            height: 100%;
            width: 100%;
        }
    }

    @media only screen and (max-width: 420px) {
        margin-left: calc(40% / 3);
    }

    @media only screen and (max-width: 375px) {
        margin-left: calc(36% / 3);
    }

    @media only screen and (max-width: 360px) {
        margin-left: calc(35% / 3);
    }
}

.scale {
    scale: 1.3;
    transform: rotate(-12deg);
}
