@import 'src/shared/styles/index.scss';

.container {
    position: absolute;
    right: 0px;
    width: 36%;
    min-width: 520px;
    z-index: $mid-zindex;

    &Close {
        background: $black;
        padding: 2px;
        font-size: 16px;
        transform: rotate(45deg);
        font-weight: 700;
        cursor: pointer;
    }
}

.files {
    // height: calc((100% - 90px));
    overflow: hidden;
    overflow-y: scroll;
}
