@import '../../shared/styles/index.scss';

.overlay {
    @extend %full-page-fixed;
    @extend %flex-center;

    background: rgba(0, 0, 0, 0.1);
    z-index: 100;
}

.delete {
    position: absolute;
    top: 30%;
    left: 38%;
    background: $white;
    box-shadow: 0px 1px 1px 0px $grey-30;
    z-index: $mid-zindex;
    padding: 16px;
    border-radius: 12px;

    &Title {
        font-weight: 600;
    }

    &Subtitle {
        font-size: $font_size_14;
        margin: 16px 0px 20px;
    }

    &Ctas {
        @extend %flex-center-vertical;

        justify-content: flex-end;
        margin: 16px 0px 0px;

        &Btn {
            background-color: $white;
            border: 1px solid $grey-30;
            padding: 4px 24px;
            border-radius: 24px;
            text-align: center;
            margin-left: 16px;
            font-weight: 600;
            cursor: pointer;

            &:hover {
                background: $blue-25;
            }
        }
    }
}
