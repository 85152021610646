@import 'src/shared/styles/index.scss';

.overlay {
    width: 100%;

    &Header {
        @extend %flex-center-vertical;

        justify-content: space-between;
        padding: 20px 16px;
        height: 72px;
        font-size: $font-size-20;
        font-weight: 500;

        &Cta {
            font-size: $font-size-16;
            cursor: pointer;
            font-weight: 600;
        }
    }
}

.body {
    height: calc(100vh - 74px);
    overflow: hidden;
    overflow-y: scroll;
}

.container {
    @extend %flex;

    flex-direction: column;
    padding: 32px 16px;
    width: 640px;
    margin: 24px auto;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
    position: relative;

    &Nudge {
        --f: 10px; /* control the folded part*/
        --r: 15px; /* control the ribbon shape */
        --t: 32px; /* the top offset */
        position: absolute;
        inset: var(--t) calc(-1 * var(--f)) auto auto;
        height: 40px;
        padding: 2px 32px var(--f) calc(16px + var(--r));
        clip-path: polygon(
            0 0,
            100% 0,
            100% calc(100% - var(--f)),
            calc(100% - var(--f)) 100%,
            calc(100% - var(--f)) calc(100% - var(--f)),
            0 calc(100% - var(--f)),
            var(--r) calc(50% - var(--f) / 2)
        );
        box-shadow: 0 calc(-1 * var(--f)) 0 inset #0005;
    }
}

.header {
    margin-bottom: 12px;

    &Title {
        font-size: $font-size-18;
        font-weight: 600;
    }

    &Subtext {
        margin-top: 12px;
        font-size: $font-size-14;
        font-weight: 500;
    }
}

.list {
    background: $white;
}

.delete {
    width: 20px;
    height: 20px;
    cursor: pointer;

    &:hover {
        color: $warning;
    }
}
