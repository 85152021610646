@import '../../../../../shared/styles/index.scss';

.container {
    height: 100%;
    background: $white;
    padding: 0px 32px 16px;

    &Title {
        font-family: 'calibri';
    }

    &Data {
        flex: 0.7;
        margin: 32px 0px;

        &Title {
            font-weight: 600;
            font-size: 18px;
        }

        &Text {
            text-align: justify;
            margin-top: 8px;
            font-size: 16px;
            max-width: 90%;
        }

        &Item {
            @extend %flex;

            flex-wrap: nowrap;
            margin-bottom: 12px;
            list-style: disc;
        }
    }
}

.wrapper {
    margin: 32px 16px;
    min-height: 500px;
    // max-height: calc(100vh - 280px);
    overflow-y: scroll;
    background: $white;
}

.button {
    @extend %flex-center;

    margin: 16px 0px;
}

.empty {
    margin-top: 16%;
    text-align: center;
}

.list {
    margin: 0px;
}
