@font-face {
    font-family: 'icomoon';
    src: url('./icomoon.eot');
    src: url('./icomoon.eot') format('embedded-opentype'), url('./icomoon.ttf') format('truetype'),
        url('./icomoon.woff') format('woff'), url('./icomoon.svg') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

:global .icon-home:before {
    content: '\e908';
}

:global .icon-tag:before {
    content: '\e915';
}

:global .icon-thumbs-down:before {
    content: '\e916';
}

:global .icon-thumbs-up:before {
    content: '\e917';
}

:global .icon-bin:before {
    content: '\e9ad';
}

:global .icon-add:before {
    content: '\e906';
}

:global .icon-file:before {
    content: '\e905';
}

:global .icon-pencil:before {
    content: '\e90a';
}

:global .icon-target:before {
    content: '\e90b';
}

:global .icon-eye-off:before {
    content: '\e918';
}

:global .icon-eye:before {
    content: '\e919';
}

:global .icon-command:before {
    content: '\ea4e';
}

:global .icon-cancel:before {
    content: '\ea0f';
}

:global .icon-accept:before {
    content: '\ea10';
}

:global .icon-search:before {
    content: '\e986';
}

:global .icon-filter:before {
    content: '\e91a';
}

:global .icon-hubspot:before {
    content: '\e91b';
    color: #ff7959;
    font-weight: 700;
}

:global .icon-linkedin:before {
    content: '\eaca';
}

:global .icon-users::before {
    content: '\e907';
}

:global .icon-case-study::before {
    content: '\e91c';
}

:global .icon-organization::before {
    content: '\e90d';
}

:global .icon-ascending::before {
    content: '\e91e';
}

:global .icon-descending::before {
    content: '\e91d';
}

:global .icon-arrow-right::before {
    content: '\e91f';
}

:global .icon-arrow-left::before {
    content: '\e920';
}

:global .icon-badge::before {
    content: '\e911';
}

:global .icon-shield::before {
    content: '\e921';
}

:global .icon-lightning::before {
    content: '\e922';
}

:global .icon-heart::before {
    content: '\e923';
}

:global .icon-offer::before {
    content: '\e924';
}

:global .icon-chart::before {
    content: '\e925';
}

:global .icon-bulb::before {
    content: '\e926';
}

:global .icon-profile::before {
    content: '\e927';
}

:global .icon-triangle-right::before {
    content: '\e928';
}

:global .icon-triangle-down::before {
    content: '\e929';
}

:global .icon-clock::before {
    content: '\e94e';
}

:global .icon-star::before {
    content: '\e9d7';
}

:global .icon-hourglass::before {
    content: '\e92a';
}

:global .icon-gratitude::before {
    content: '\e92b';
}

:global .icon-discount::before {
    content: '\e92c';
    font-weight: 700;
}

:global .icon-headphones::before {
    content: '\e92d';
}

:global .icon-globe::before {
    content: '\e92e';
    font-weight: 700;
}

:global .icon-database::before {
    content: '\e92f';
}

:global .icon-warning::before {
    content: '\ea07';
}

:global .icon-call::before {
    content: '\e931';
}

:global .icon-chevron-up::before {
    content: '\e932';
}

:global .icon-compare::before {
    content: '\e933';
}

:global .icon-headline::before {
    content: '\e934';
}

:global .icon-testimonial::before {
    content: '\e935';
    font-weight: 500;
    color: #000;
}

:global .icon-trend::before {
    content: '\e936';
    font-weight: 500;
    color: #000;
}

:global .icon-visual-effect::before {
    content: '\e937';
    font-weight: 500;
    color: #000;
}

:global .icon-motorway::before {
    content: '\e938';
}

:global .icon-wallet::before {
    content: '\e939';
}

:global .icon-study::before {
    content: '\e93a';
}

:global .icon-eyedropper::before {
    content: '\e93b';
}

:global .icon-computer::before {
    content: '\e93c';
}

:global .icon-flag-checkered::before {
    content: '\e93d';
}

:global .icon-id-card::before {
    content: '\e93e';
}

:global .icon-man::before {
    content: '\e93f';
}

:global .icon-hammer::before {
    content: '\e996';
}

:global .icon-link::before {
    content: '\e9cb';
}

:global .icon-attachment::before {
    content: '\e9cd';
}

:global .icon-location:before {
    content: '\e947';
}

:global .icon-building:before {
    content: '\e90c';
}

:global .icon-save::before {
    content: '\e940';
}

:global .icon-fb::before {
    content: '\ea91';
}

:global .icon-lock::before {
    content: '\e941';
}

:global .icon-construction::before {
    content: '\e942';
}

:global .icon-triangle-up::before {
    content: '\e943';
}

:global .icon-robot::before {
    content: '\e944';
}

:global .icon-book::before {
    content: '\e945';
}

:global .icon-contacts::before {
    content: '\e946';
}

:global .icon-folder::before {
    content: '\e948';
}

:global .icon-layers::before {
    content: '\e949';
}
