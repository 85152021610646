@import '../../shared//styles/index.scss';

.container {
    @extend %flex-center-vertical;

    flex-wrap: wrap;
    margin: 8px 0px;
    padding: 0px 8px 8px;
    border-radius: 8px;
    border: 1px solid $black;
    width: 100%;
    position: relative;
    color: $black;

    &Item {
        @extend %flex-center-vertical;

        padding: 2px 8px;
        border: 1px solid $grey-50;
        border-radius: 12px;
        cursor: pointer;
        margin: 8px 4px 0px;
    }

    &Input {
        border: none;
        outline: none;
        flex-grow: 1;
        font-size: 16px;
        outline: none;
        text-align: justify;
        margin-top: 8px;
        padding: 0px 8px;
    }
}
