@import 'src/shared/styles/index.scss';

.fields {
    @extend %flex;

    flex-wrap: wrap;

    &Field {
        &#{&} {
            width: calc(100% / 3);
            margin: 0px;
            padding: 4px 16px 4px 0px;
        }
    }
}
