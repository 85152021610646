@import 'src/shared/styles/index.scss';

.form {
    width: 100%;

    &Wrapper {
        @extend %flex;

        align-items: flex-start;
        flex-wrap: wrap;
    }

    &Urls {
        border: none;
        border-bottom: 1px solid $black;
        border-radius: 0px;
        padding: 0px;
        margin: 0px;
        width: 100%;
    }

    &Urls {
        display: block;
        padding: 8px 0px 4px;
        width: 100%;
    }

    &Submit {
        @extend %flex-center;

        margin: 14px 0px 0px;

        text-align: center;
    }
}

.tile {
    min-height: 72px;
    align-self: normal;
}
