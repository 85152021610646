@import 'src/shared/styles/index.scss';

.container {
    margin: 4px 0px;
    border-bottom: 2px solid $grey-30;
    position: relative;

    &Title {
        @extend %flex;

        justify-content: space-between;
        padding: 2px 0px;
        border-radius: 4px;

        &Text {
            font-size: 16px;
            font-weight: 500;
        }

        &Error {
            align-self: center;
            font-size: 14px;
            background: linear-gradient(10deg, $red-10 0%, $warning 74%);
            background-clip: text;
            font-weight: 600;
            -webkit-text-fill-color: transparent;
            padding: 0px 4px;
        }
    }

    &Content {
        padding: 8px 4px;
        max-height: 24vh;
        overflow: hidden;
        overflow-y: auto;
    }
}
