@import '../../shared/styles/index.scss';

.accordion {
    border: 1px solid $grey-30;
    border-radius: 12px;
    margin-bottom: 16px;
    overflow: hidden;

    &Header {
        @extend %flex-center-vertical;

        justify-content: space-between;
        padding: 10px;
        background-color: $grey-30;
        color: $black;
        cursor: pointer;

        &Open {
            background-color: $blue;
            color: $white;
        }

        &:hover {
            background-color: $blue;
            color: $white;
        }

        &count {
            margin-left: 10px;
            font-size: 14px;
            font-weight: bold;
        }

        &icon {
            font-size: 16px;
            transition: transform 0.2s ease-in-out;

            &IconChevronup {
                transform: rotate(180deg);
            }
        }
    }

    &Body {
        background: $white;
    }
}

.list {
    background: $white;

    &Item {
        @extend %flex-center-vertical;

        padding: 8px;
        height: 32px;
        border-top: 1px solid $blue;
        height: fit-content;

        &Col {
            width: calc(100% / 3 - 24px);
            margin-right: 4px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            &:first-child {
                width: 48px;
            }
        }

        &:hover {
            background: $grey-30;
            // color: $white;
            // cursor: pointer;
        }
    }

    &Head {
        background: $grey-30;
        border-top: none;

        &:hover {
            background: $grey-30;
            color: $black;
            cursor: auto;
        }
    }

    &Empty {
        @extend %flex-center;

        text-align: center;
        font-size: 16px;
        height: 40px;
    }
}

.modal {
    @extend %full-absolute;
    background: rgba($black, 0.5);
    z-index: 2;
    backdrop-filter: blur(1px);
    -webkit-backdrop-filter: blur(1px);

    &Container {
        position: absolute;
        top: 24%;
        left: 40%;
        width: fit-content;
        max-width: 500px;
        min-width: 320px;
        height: fit-content;
        min-height: 100px;
        background: rgba($white, 1);
        box-shadow: 0px 0px 8px 4px $grey-30;
        border-radius: 12px;
        padding: 20px;

        &Close {
            position: absolute;
            right: 8px;
            top: 8px;
            border-radius: 100%;
            font-size: 20px;
            width: 24px;
            text-align: center;
            height: 24px;
            cursor: pointer;
            background: $grey;
        }

        &Text {
            margin-top: 12px;
            color: $black;
        }
    }
}

.textcontent {
    cursor: pointer;
}
