@import '../../../shared/styles/index.scss';

.container {
    @extend %flex;

    flex-direction: column;
    justify-content: space-between;
    width: 100%;

    &ButtonLeft,
    &ButtonRight {
        @extend %flex;

        justify-content: flex-end;
    }

    &ButtonRight {
        justify-self: flex-end;
        flex-grow: 1;
    }

    &Footer {
        @extend %flex-center-vertical;

        justify-content: space-between;
        margin: 8px 0px;
        padding: 16px;
    }
}

.title {
    font-size: $font_size_28;
    font-weight: 600;
}

.draft {
    padding: 8px 32px 16px;
    min-height: calc(100vh - 100px);

    &Title {
        font-weight: 600;
        font-size: $font_size_20;
    }

    &Table {
        margin-bottom: 16px;

        &Item {
            @extend %flex-center-vertical;

            padding: 8px 16px;
            border-bottom: 1px solid $grey-30;
            cursor: pointer;

            &:hover {
                background: $blue-light;
                border-radius: 32px;
            }

            &Icon {
                height: 24px;
                width: 24px;
                color: rgba($vivid-blue, 0.5);
                font-weight: 600;
                flex: 0.05;
                text-align: left;
            }

            &Text {
                flex: 0.2;
                text-align: left;
                font-size: $font_size_16;

                &:nth-child(2) {
                    flex: 0.75;
                    max-width: 720px;
                }

                &Icon {
                    height: 20px;
                    width: 20px;
                }
            }

            &Stat {
                @extend %flex-center;

                flex: 0.08;
                text-align: center;
                text-transform: capitalize;
                margin: 0px 32px;
                padding: 0px 8px;
                font-size: $font_size_14;
                height: 28px;
            }

            &Dots {
                color: $black;
                height: 30px;
                width: 30px;
                margin: 0px 12px;
                padding: 0px 8px;
                border-radius: 100%;

                &Icon {
                    width: 100%;
                    height: 100%;
                }

                &:hover {
                    background: $blue-25;
                }
            }
        }
    }
}

.empty {
    @extend %flex-center;

    height: 30vh;
    font-size: $font_size_18;
}

.menu {
    position: absolute;
    background: $white;
    box-shadow: 0px 1px 1px 0px $grey-30;
    border: 2px solid $grey-30;
    z-index: $mid-zindex;

    width: 148px;
    border-radius: 8px;

    &Item {
        font-weight: 500;
        font-size: $font_size_14;
        width: 100%;
        padding: 12px 8px;
        cursor: pointer;
        border-bottom: 2px solid $light-purple;

        &:hover {
            background: $light-purple;
        }

        &:last-child {
            border: none;
        }
    }
}

.overlay {
    @extend %full-page-fixed;
    @extend %flex-center;

    background: rgba(0, 0, 0, 0.1);
}

.delete {
    position: absolute;
    top: 80px;
    left: 38%;
    background: $white;
    box-shadow: 0px 1px 1px 0px $grey-30;
    z-index: $mid-zindex;
    padding: 16px;
    border-radius: 12px;

    &Title {
        font-weight: 600;
    }

    &Subtitle {
        font-size: $font_size_14;
        margin: 16px 0px 20px;
    }

    &Ctas {
        @extend %flex-center-vertical;

        justify-content: flex-end;
        margin: 16px 0px 0px;

        &Btn {
            background-color: $white;
            border: 1px solid $grey-30;
            padding: 4px 24px;
            border-radius: 24px;
            text-align: center;
            margin-left: 16px;
            font-weight: 600;
            cursor: pointer;

            &:hover {
                background: $blue-25;
            }
        }
    }
}

.query {
    @extend %flex;

    justify-content: space-between;
    flex-wrap: wrap;

    &Input {
        &#{&} {
            width: 40%;
        }
    }
}
