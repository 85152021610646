@import '../../styles.module.scss';

.wrapper {
    flex: 1;

    &Title {
        @extend %flex-center-vertical;

        justify-content: space-between;
        max-width: 100%;
        font-weight: 600;
        font-size: 18px;
    }
}

.list {
    padding: 0px;
    flex: 1;

    &Item {
        @extend %flex;

        width: 100%;
        border-bottom: 1px dashed #ccc;
        padding-bottom: 8px;
        margin: 8px 0px;

        &:last-child {
            border: none;
            margin: 0px;
        }

        &Container {
            @extend %flex;

            justify-content: space-between;
            flex: 1;

            &Wrap {
                @extend %flex;

                flex-direction: column;
                justify-content: space-between;
                max-width: 48%;
            }

            &Text {
                flex: 1;
                margin-bottom: 16px;
            }

            &Cta {
                @extend %flex-center;

                flex: 1;
                max-height: 32px;
            }

            &Select {
                @extend %flex-center;
                margin: 4px;
                height: 100px;
                width: 100%;
            }
        }

        &Icons {
            @extend %flex;

            justify-content: flex-end;
            margin: 8px 16px 0px;
            width: 56px;
        }

        &Textbox {
            padding: 12px;
            border-color: #ccc;
        }
    }
}

.icons {
    @extend %flex-center-vertical;

    align-self: flex-start;

    &Pencil,
    &Accept,
    &Cancel,
    &Mail {
        position: relative;
        width: 20px;
        height: 20px;
        cursor: pointer;
    }

    &Pencil {
        color: $grey;
    }

    &Accept {
        width: 20px;
        height: 20px;
        color: $softLimeGreen;
        margin-left: 12px;
    }

    &Cancel {
        color: $red;
    }

    &Mail {
        margin-left: 8px;
        color: $grey;

        &:hover {
            color: $black;
        }
    }
}

.diff {
    background: rgba(#8ee0b6, 0.25);
}
