@import 'src/shared/styles/index.scss';

.overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.content {
    background-color: $white;
    width: 480px;
    height: 100%;
    overflow-y: auto;
    padding: 20px;
    position: fixed;
    top: 0;
    right: 0;
    transform: translateX(100%);
    animation: slideIn 0.3s ease-in-out forwards;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

@keyframes slideIn {
    from {
        transform: translateX(100%);
    }
    to {
        transform: translateX(0);
    }
}

.closeButton {
    position: absolute;
    top: 24px;
    right: 20px;
    width: 24px;
    height: 24px;
    color: $white;
    padding: 2px;
    background: $black;
    border-radius: 100%;
    cursor: pointer;
    transition: transform 0.3s ease-in-out;
    transform: rotate(45deg);
}

.title {
    font-weight: 700;
    font-size: $font_size_28;
}

.input {
    width: calc(100% - 20px); /* Adjust width to account for padding */
    padding: 10px;
    font-size: 16px;
    border-radius: 4px;
    min-height: 40px;
}

.button {
    background-color: $black;
    color: $white;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    font-size: $font_size_16;

    &:hover {
        background-color: $blue-10;
    }
}
