@import 'src/shared/styles/index.scss';

.container {
    // width: calc(100vw - 370px);
    flex: 1;
    overflow: hidden;

    &Error {
        align-self: center;
        font-size: 14px;
        background: linear-gradient(10deg, $red-10 0%, $warning 74%);
        background-clip: text;
        font-weight: 600;
        -webkit-text-fill-color: transparent;
        padding: 0px 4px;
    }
}

.search {
    &#{&} {
        color: $black;
        margin-left: 12px;
        width: 180px;
        padding: 0px;
    }

    &Input {
        &#{&} {
            min-height: 36px;
        }
    }
}

.table {
    height: calc(100vh - 350px);
    overflow: scroll;

    &::-webkit-scrollbar {
        height: 4px;
    }
}

.data {
    min-width: 260px;
    width: 260px;
}

.wCheck {
    min-width: 24px;
    width: 24px;
    max-width: 24px;
}

.w20 {
    min-width: 20px;
    width: 20px;
}

.w40 {
    min-width: 60px;
    width: 60px;
}

.w100 {
    min-width: 64px;
    width: 64px;
}

.colData {
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    white-space: nowrap;
    overflow: hidden;
}

.wfit {
    width: fit-content;
    min-width: fit-content;
}

.shimmer {
    &Container {
        margin-top: 10px;
        position: sticky;
        left: 0px;
        height: 10px;
        background: #e0e0e0;
    }

    height: 100%;
    background: linear-gradient(
        90deg,
        rgba(105, 105, 105, 0.1) 25%,
        rgba(169, 169, 169, 0.4) 50%,
        rgba(105, 105, 105, 0.8) 100%
    );
    animation: shimmer 1.5s infinite;
}

.icon {
    @extend %flex-center;

    background: $white;
    width: 28px;
    height: 28px;
    padding: 12px;
    border-radius: 100%;
}

@keyframes shimmer {
    0% {
        transform: translateX(-100%);
    }
    100% {
        transform: translateX(100%);
    }
}
