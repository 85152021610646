@import '../../shared/styles/index.scss';

.table {
    @extend %flex;

    flex-direction: column;
    border: 1px solid $grey-30;
    padding: 8px 0px;
    margin: 16px 0px;
    border-radius: 16px;
    box-shadow: 0px 0px 4px 2px $grey-30;

    &Row {
        @extend %flex;

        border-bottom: 1px solid #ccc;

        &:last-child {
            border-bottom: none;
        }
    }

    &Header {
        font-weight: 600;
        text-transform: none !important;
    }

    &Cell {
        margin: auto;
        width: calc(100% / 6);
        flex-wrap: wrap;
        word-wrap: break-word;
        padding: 5px;
        text-align: center;
        text-transform: capitalize;

        // &:nth-child(2) {
        //     width: 72px;
        // }

        &Text {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            cursor: pointer;
        }
    }
}

.link {
    cursor: pointer;
    text-decoration: underline;
    text-transform: none;
    margin: 16px;
}

.folder {
    width: 32px;
    height: 32px;
    color: rgba($vivid-blue, 0.5);
    font-weight: 600;
    cursor: pointer;
    transition: transform 0.4s ease;

    &Links {
        @extend %flex-center-vertical;

        justify-content: space-between;
        width: 450px;
    }

    &Type {
        margin: 0px 16px;
        text-transform: uppercase;
    }

    &:hover {
        transform: scale(1.05);
        color: rgba($vivid-blue, 0.9);
    }
}
