@import '../../shared//styles/index.scss';

.container {
    width: 100%;
    // background-color: #f0f0f0;
    border-radius: 8px;
    overflow: hidden;
    position: relative;
    height: 30px;
    display: flex;
    align-items: center;
}

.bar {
    height: 100%;
    background-color: rgba(71, 85, 105, 0.4);
    position: relative;
    transition: width 0.3s ease;
    border-radius: inherit;
}

.shimmer {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0.1) 25%,
        rgba(255, 255, 255, 0.2) 50%,
        rgba(255, 255, 255, 0.1) 75%
    ); // Shimmer effect colors
    animation: shimmer 1.5s infinite;
}

.text {
    @extend %flex-center;
    
    height: 100%;

    color: $black;
    position: absolute;
    width: 100%;
    text-align: center;
    z-index: 2;
}

@keyframes shimmer {
    0% {
        transform: translateX(-100%);
    }
    100% {
        transform: translateX(100%);
    }
}
