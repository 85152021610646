@import 'src/shared/styles/index.scss';

.container {
    flex: 1;
}

.caseStudy {
    @extend %flex;

    gap: 0px 40px;
    flex-wrap: wrap;
    margin: 20px;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 5px;
    background-color: #f9f9f9;
    text-align: justify;

    &Section {
        margin: 4px 0px 32px;
        flex: 1 1 36%;
    }
}

.caseStudy h3 {
    font-size: 18px;
    margin-bottom: 4px;
}

.caseStudy strong {
    font-weight: bold;
    margin: 4px 0px;
}

.faqs {
    margin: 20px;
    border-top: 1px solid #ddd;
    padding-top: 8px;
    background-color: #f9f9f9;
    text-align: justify;

    &List {
        list-style: disc;
        padding: 20px;

        &Item {
            padding: 12px 0px;
        }
    }
}
