@import '../../../shared/styles/index.scss';

.container {
    @extend %full-page-fixed;

    z-index: $max-zindex;
}

.loader {
    @extend %flex-center;

    height: 100%;
    backdrop-filter: blur(1px);
    -webkit-backdrop-filter: blur(1px);
}
