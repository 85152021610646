@import 'src/shared/styles/index.scss';

.sidebar {
    flex: 0.15;
    padding: 20px 16px;
    height: calc(100vh - 0px);
    overflow: hidden;
    overflow-y: scroll;
}

.label {
    font-family: Helvetica;
    font-weight: 600;
    font-size: 16px;
}
