@import '../../shared//styles/index.scss';

.container {
    @extend %flex-center-vertical;

    flex-wrap: wrap;
    padding: 8px 0px;

    &Button {
        padding: 4px 12px;
        border-radius: 8px;
        width: 172px;
        text-align: center;
        font-size: 16px;
        font-weight: 600;
        cursor: pointer;
    }

    &Text {
        font-weight: 500;
        margin-left: 4px;
    }
}

.disabled {
    background: $grey-80;
    pointer-events: none;
}

.icon {
    width: 30px;
    height: 30px;
    cursor: pointer;

    &Img {
        height: 100%;
        width: 100%;
    }
}

.files {
    @extend %flex;

    flex-wrap: wrap;
    margin-right: 8px;

    &Wrapper {
        @extend %flex-center-vertical;
    }

    &:first-of-type {
        margin-left: 8px;
    }
}
