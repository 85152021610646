%full-page-fixed {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

%full-absolute {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

%flex {
    display: flex;
}

%flex-center-vertical {
    @extend %flex;
    align-items: center;
}

%flex-center-horizontal {
    @extend %flex;
    justify-content: center;
}

%flex-center {
    @extend %flex-center-horizontal;
    @extend %flex-center-vertical;
}

%line-clamp {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    overflow: hidden;
    -webkit-box-orient: vertical;
}

%error {
    align-self: end;
    font-size: 14px;
    font-weight: 600;
    color: $warning;
}

;@import "sass-embedded-legacy-load-done:60";