@import '../../../../../shared/styles/index.scss';

.container {
    padding: 16px 0px;

    &Title {
        margin: 12px 0px;
        font-size: $font_size_20;
        font-weight: 600;
    }

    &Headings {
        @extend %flex-center-vertical;

        font-size: 20px;
        padding: 8px 0px;
        border-bottom: 2px solid $grey-30;

        &Accept {
            flex: 0.075;
        }

        &Left {
            flex: 0.85;
            justify-content: flex-start;
        }

        &Right {
            flex: 0.075;
            justify-content: flex-start;
        }
    }

    &Content {
        position: relative;
        min-height: calc(100vh - 360px);

        &Row {
            @extend %flex;

            padding: 8px 0px;
        }

        &Accept {
            @extend %flex;

            align-items: flex-start;
            flex: 0.075;
            max-width: 64px;
        }

        &Action {
            @extend %flex;

            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            flex: 0.1;
            position: relative;
            height: fit-content;
        }
    }

    &Footer {
        @extend %flex-center-vertical;

        margin: 8px 0px;
    }
}

.wordRange {
    width: 116px;
    margin: 8px 0px;
}

.tags {
    @extend %flex-center-vertical;

    position: absolute;
    right: 16%;
    top: 40px;
    width: 40%;
    max-width: 420px;
    flex-wrap: wrap;

    &Name {
        padding: 4px 8px;
        background: $blue;
        color: $white;
        margin: 4px 8px;
        border-radius: 8px;
        box-shadow: 0px 0px 2px 0px $darkBlue;
    }
}

.instruction {
    &Box {
        position: absolute;

        right: 0px;
        width: 480px;
        z-index: 1;
    }

    &Cta {
        min-width: 40px;
        width: 40px;
        height: 32px;
    }
}

;@import "sass-embedded-legacy-load-done:131";