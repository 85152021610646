@import 'src/shared/styles/index.scss';

.folder {
    width: 100%;
    height: 100%;

    &Container {
        min-width: 24px;
        width: 24px;
        height: 24px;
    }
}

.container {
    width: calc(97% / 4);
    min-width: 300px;

    @media only screen and (max-width: 1580px) {
        width: calc(97% / 3);
        min-width: 300px;

        &:nth-child(3n) {
            margin-right: 0px;
        }
    }

    @media only screen and (max-width: 1240px) {
        width: calc(97% / 2);
    }

    @media only screen and (max-width: 940px) {
        width: 100%;
    }
}

.orgicon {
    height: 16px;
    width: 16px;
}

.dots {
    height: 16px;
    width: 16px;
}
