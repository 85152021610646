@import '../../../../shared//styles/index.scss';

.container {
    @extend %flex-center;

    margin: 16px 0px 0px;
    padding: 4px;
    border-radius: 8px;
    background-color: $grey-30;
    width: 128px;
    text-align: center;
    font-weight: 600;
    box-shadow: 0px 0px 2px 2px $grey-50;
    transition: all 0.2s ease-in-out;
    cursor: pointer;

    &:hover {
        transform: scale(1.1);
    }
}

.title {
    color: $black;
    margin-left: 8px;
}

.icon {
    width: 24px;
    height: 24px;

    &Img {
        height: 100%;
        width: 100%;
    }
}
