@import 'src/shared/styles/index.scss';

.card {
    position: relative;
    width: 420px;

    &Container {
        @extend %flex;

        flex-wrap: wrap;
        align-items: start;
    }

    &Body {
        display: grid;
        grid-template-columns: repeat(1, minmax(0, 1fr));

        &Leftrow {
            min-width: 200px;
        }

        &Rightrow {
            width: 168px;
            white-space: nowrap;
            overflow: hidden;
            display: inline-block;
            text-overflow: ellipsis;
        }
    }

    &Footer {
        &Data {
            width: 365px;
            white-space: nowrap;
            overflow: hidden;
            display: inline-block;
            text-overflow: ellipsis;
        }
    }
}

.edit {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
}
