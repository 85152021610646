@import 'src/shared/styles/index.scss';

.overlay {
    @extend %full-page-fixed;
    @extend %flex-center;

    background: rgba($black, 0.4);
    z-index: 100;
}

.container {
    position: relative;
    width: 280px
}

.closeButton {
    position: absolute;
    top: 14px;
    right: 16px;
    width: 24px;
    height: 24px;
    color: $white;
    padding: 2px;
    border-radius: 100%;
    cursor: pointer;
    transition: transform 0.3s ease-in-out;
    transform: rotate(45deg);
}

.tag {
    position: absolute;
    padding: 4px 8px;
    top: -4px;
    right: -14px;
    font-size: 12px;
    font-weight: 600;
}
