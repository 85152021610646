@import '../../shared/styles/index.scss';

.container {
    @extend %flex;

    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    padding: 20px 16px;
    width: 240px;
    height: 100vh;
    position: sticky;
    bottom: 0;
    top: 0;

    &Title {
        font-size: 20px;
        font-weight: 600;
        cursor: pointer;
        margin-bottom: 20px;

        &Small {
            text-align: center;
        }

        &Icon {
            width: 32px;
            height: 32px;
        }
    }

    &Small {
        background: $blue-light;
        border-right: 1px solid $blue-light;
        width: 76px;
        align-items: center;
    }
}

.user {
    @extend %flex-center-vertical;

    width: 132px;
    padding: 0px;
    border-radius: 50px;
    border-left: none;
    cursor: pointer;

    &Small {
        width: 100%;
        border-radius: 100%;

        &Image {
            width: 40px !important;
            height: 40px !important;
        }
    }

    &Image {
        width: 36px;
        height: 36px;
        border-radius: 100px;
        overflow: hidden;

        &Pic {
            width: 100%;
            height: 100%;
        }
    }

    &Detail {
        margin: 0px 4px;
    }
}

.campaign {
    margin: 20px 0px;

    &List {
        padding-left: 16px;

        &Item {
            padding: 4px 8px;
            border-radius: 12px;
            cursor: pointer;

            &:hover {
                background-color: $blue-light;
            }
        }
    }
}

.add {
    @extend %flex-center;

    margin: 12px 0px;
    width: 36px;
    height: 36px;
    cursor: pointer;
    will-change: transform;
    transition: 0.2s ease-in-out transform;

    &Small {
        justify-content: center;
        width: 100%;
    }

    &Icon {
        width: 32px;
        height: 32px;
        color: $white;
        padding: 4px;
        background: $blue-10;
        border-radius: 100%;
    }

    &:hover {
        transform: scale(1.1);
    }
}

.chip {
    border-color: $blue-light;

    &Cta {
        margin: 2px 0px;
    }

    &Icon {
        width: 20px;
        height: 20px;
        margin-right: 8px;
    }

    &Active {
        background: $blue-light;
        width: 36px;
    }
}
