@import '../../shared//styles/index.scss';

.container {
    @extend %flex-center-vertical;

    max-width: 200px;
    width: fit-content;

    &Label,
    &Input {
        cursor: pointer;
    }

    &Input {
        margin: 0px;
        height: 24px;
        width: 24px;
        appearance: none;
        border-radius: 100%;
        background: $white;

        &::before {
            content: '';
            display: block;
            width: 80%;
            height: 80%;
            margin: 20% auto;
            border-radius: 50%;
        }
    }

    &Label {
        @extend %flex-center;

        padding-left: 4px;
        word-wrap: break-word;
    }
}

.icons {
    width: 24px;
    height: 24px;
    margin-right: 8px;
}
