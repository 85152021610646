@import '../../../../../shared/styles/index.scss';

.container {
    @extend %flex;

    justify-content: space-between;
    margin: 0px;
    overflow: hidden;
}

.main {
    flex: 0.85;
    height: calc(100vh - 0px);
    overflow: hidden;
    overflow-y: scroll;
    padding: 20px 20px 32px;

    &Field {
        width: 49%;
    }

    &Input {
        border-radius: 8px;
        font-size: $font_size_16;
    }

    &Footer {
        @extend %flex;

        justify-content: center;
        margin: 16px 0px;
    }
}

.doc {
    margin: 16px 0px;
}

.configure {
    margin: 0px;

    &Title {
        @extend %flex;

        justify-content: space-between;
        margin: 8px 0px;
        padding: 2px 0px;
        border-bottom: 2px solid $grey-30;
        border-radius: 4px;

        &Text {
            font-size: 24px;
        }

        &Error {
            @extend %error;

            display: block;
            text-align: right;
            padding: 0px 0px 8px;
        }
    }

    &Items {
        @extend %flex;

        flex-wrap: wrap;
        width: 100%;
    }

    &Item {
        width: 200px;
        margin: 8px 24px 8px 0px;
    }
}

.label {
    font-family: Helvetica;
    font-weight: 600;
    font-size: 16px;
}

.listing {
    &#{&} {
        right: 0px;
    }
}
