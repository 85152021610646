@import '../../shared//styles/index.scss';

.container {
    border-radius: 8px;
    width: 100%;
    position: relative;
}

.count {
    text-align: right;
    position: absolute;
    background: $white;
    top: -2px;
    right: 8px;
    padding: 2px 6px;
    border: 1px solid $black;
    border-radius: 8px;
    font-size: 12px;
    box-shadow: 0px 1px 2px $grey;
}

.textArea {
    min-width: 100%;
    max-width: 100%;
    min-height: 320px;
    max-height: 320px;
    resize: none;
    font-family: inherit;
    font-size: 16px;
    outline: none;
    padding: 12px 8px;
    border-radius: 8px;
    border: 1px solid $black;
    text-align: justify;
}

.warning {
    color: $red;
}
