@import '../../shared//styles/index.scss';

.container {
    padding: 8px 0px;
    border-radius: 8px;
    width: 100%;
    position: relative;
}

.icon {
    @extend %flex-center;

    position: absolute;
    width: 32px;
    height: 40px;
    padding-left: 12px;
    z-index: 1;

    &Img {
        width: 16px;
        height: 16px;
        font-size: 16px;
        color: rgba(255, 255, 255, 0.5);
    }
}

.input {
    min-width: 100%;
    max-width: 100%;
    font-family: inherit;
    font-size: 16px;
    font-weight: 400;
    outline: none;
    padding: 8px 12px;
    border-radius: 16px;
    text-align: justify;
    box-shadow: 0px 0px 1px 0px rgba($black, 0.2);
    line-height: 18px;
    min-height: 40px;

    &Icon {
        padding-left: 40px;
        position: relative;
    }
}

.info {
    @extend %flex;

    justify-content: space-between;
    margin-bottom: 8px;

    &Label {
        font-size: 16px;
        font-weight: 600;
    }

    &Error {
        @extend %error;

        font-size: 12px;
        max-width: 280px;
    }
}

.select {
    input {
        cursor: text;
        outline: none;

        &:hover {
            border: none;
        }

        &:focus {
            border-color: $blue-10;
            outline: 1px solid $blue-10;
        }
    }
}

.readonly {
    border-color: $grey-50;
    outline: none;
    color: rgba(255, 255, 255, 0.5);
    &:focus {
        border-color: $grey-50;
        outline: none;
    }
}

.textarea {
    position: relative;

    &Btn {
        font-size: $font-size-14;
    }
}

.tooltip {
    content: attr(title);
    min-width: 0px;
    max-width: 320px;
    transition: opacity 0.2s ease-in-out;
    color: $black;
}
