@import '../../../shared/styles/index.scss';

.container {
    width: 100%;

    &ButtonLeft,
    &ButtonRight {
        @extend %flex;

        justify-content: flex-end;
    }

    &ButtonRight {
        justify-self: flex-end;
        flex-grow: 1;
    }

    &Footer {
        @extend %flex-center-vertical;

        justify-content: space-between;
        margin: 8px 0px;
        padding: 16px;
    }
}
