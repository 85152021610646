@import './shared/styles/index.scss';

* {
    box-sizing: border-box;
    font-family: 'Helvetica', 'Arial', 'sans-serif';
}

h1 {
    font-family: 'Helvetica', 'Arial', 'sans-serif' !important;
    font-size: 18px !important;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
        'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: $body-background;
    overscroll-behavior-y: none;
    background-repeat: no-repeat;
    background-size: 100%;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* width */
::-webkit-scrollbar {
    width: 4px;
}

/* Track */
::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: $blue-25;
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: $blue-25;
}
