@import '../../shared//styles/index.scss';

.container {
    @extend %flex-center-vertical;

    max-width: 250px;
    cursor: pointer;
}

.input {
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
}

.checkbox {
    display: inline-block;
    height: 100%;
    width: 100%;
    background: $white;
    border: 2px solid $grey-80;
    border-radius: 4px;
    padding: 4px;

    &Active {
        border: 0px;
        border-radius: 4px;
        // box-shadow: 1px 1px 2px $darkBlue-30;
        padding: 6px;
        background-color: $darkBlue-30;
    }
}

.box {
    height: 24px;
    width: 24px;
    margin-right: 4px;
}
