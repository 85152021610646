@import '../../../shared/styles/index.scss';

.wrapper {
    position: relative;
    min-height: calc(100vh - 40px);
}

.container {
    @extend %full-absolute;

    background: $white;
    box-shadow: 0px 0px 8px 4px $grey-30;
    width: 40%;
    height: 600px;
    height: fit-content;
    max-width: 480px;
    min-width: 360px;
    margin: auto;
    padding: 16px;
    border-radius: 12px;

    &Heading {
        text-align: center;
        margin-top: 16px;
    }

    &Footer {
        text-align: center;
        margin: 16px 0px;
        font-size: 14px;

        &Signup {
            margin-left: 4px;
            font-weight: 600;
            cursor: pointer;
        }
    }
}

.title {
    font-size: 24px;
    font-weight: 600;
}

.ctas {
    @extend %flex-center;

    &Btn {
        margin-right: 0px;
    }
}

.form {
    @extend %flex-center-vertical;

    flex-direction: column;
    width: calc(100% - 32px);
    margin: 16px auto;

    &Submit {
        margin: 8px 0px 0px;
        text-align: center;
    }
}
