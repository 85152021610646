.tiptap {
    > * + * {
        margin-top: 0.75em;
    }
}

.tiptap p.is-empty::before {
    color: #adb5bd;
    content: attr(data-placeholder);
    float: left;
    height: 0;
    pointer-events: none;
}

.ProseMirror {
    min-height: 40vh;
    outline: none;
    padding: 8px;
}

.item.is-selected {
    color: white;
    background: black;
}

mark {
    background-color: #a7c7e7;
}
