@import '../../shared/styles/index.scss';

.modal {
    @extend %full-page-fixed;

    background: rgba($black, 0.5);
    z-index: 2;
    backdrop-filter: blur(1px);
    -webkit-backdrop-filter: blur(1px);
    height: 100vh;

    &Container {
        position: relative;
        margin: auto;
        top: 25%;
        width: fit-content;
        max-width: 50%;
        min-width: 320px;
        height: fit-content;
        min-height: 100px;
        max-height: 70%;
        background: rgba($white, 0.8);
        box-shadow: 0px 0px 8px 4px $grey-30;
        border-radius: 12px;
        padding: 20px;
        overflow: hidden;
        overflow-y: scroll;

        &Header {
            @extend %flex-center-vertical;
        }

        &Title {
            font-size: 18px;
            font-weight: 700;
            flex: 1;
            text-align: center;
        }

        &Close {
            @extend %flex-center;
            
            border-radius: 100%;
            font-size: 20px;
            width: 24px;
            text-align: center;
            height: 24px;
            cursor: pointer;
            background: $black;
            color: $white;
        }

        &Text {
            margin-top: 12px;
            color: $black;
        }
    }
}
