@import 'src/shared/styles';

.section {
    background: $grey-30;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    min-height: calc(100vh - 40px);

    @media screen and (max-width: 620px) {
        padding: 16px;
    }
}

.container {
    position: relative;
    width: 620px;
    margin: auto;
    background: $white;
    padding: 32px;
    border-radius: 16px;
    display: flex;

    @media screen and (max-width: 620px) {
        padding: 16px;
        width: 100%;
        flex-direction: column;
    }

    &Logo {
        width: 220px;
        margin: auto;

        @media screen and (max-width: 620px) {
            width: 120px;
        }
    }

    &Right {
        flex: 1;
        margin-left: 24px;

        &Heading {
            margin: 8px 0px;
            font-weight: $font-weight-light-mid;
            font-size: $font-size-36;

            @media screen and (max-width: 620px) {
                margin-top: 16px;
                font-size: $font-size-20;
            }
        }

        &Message {
            font-size: $font-size-18;

            @media screen and (max-width: 620px) {
                font-size: $font-size-16;
            }
        }

        &InputContainer {
            width: 100%;
            margin: 0px;
        }

        &Label {
            font-size: $font-size-18;
            font-weight: $font-weight-mid;
        }

        &Input {
            border: none;
            border-bottom: 1px solid $black;
            outline: none;
            font-size: $font-size-18;
            width: 100%;
        }

        &Button {
            font-size: $font-size-18;
            padding: 8px 32px;
        }
    }
}

.emailSentMessage {
    font-size: $font-size-20;
    font-weight: $font-weight-light-mid;
    display: flex;
    margin-top: 24px;
}
.nextButton {
    font-size: $font-size-18;
    padding: 8px 32px;
}
