@import '../../../shared/styles/index.scss';

.profile {
    @extend %flex;

    flex-direction: column;
    width: 100%;
    min-height: calc(100vh - 120px);
    // margin: 0px 16px;
    background: $white;
    border-radius: 16px;
}

.user {
    position: relative;
    background: $blue;
    // box-shadow: 0px 0px 4px 0px #000;
    z-index: 1;
    // border-radius: 16px 16px 0px 0px;
    padding: 8px 16px;

    &Image {
        border-radius: 100%;
        box-shadow: 0px 0px 4px 0px $darkBlue;
        overflow: hidden;
        width: 50px;
        height: 50px;
        margin: 0px auto 8px;

        &Img {
            width: 100%;
            height: 100%;
        }
    }

    &Add {
        position: absolute;

        top: 16px;
        right: 16px;
    }

    &Info {
        @extend %flex-center-vertical;

        justify-content: space-between;
        margin: 12px 0px;
        padding: 0px 16px;
        color: $white;

        &Text {
            text-align: center;
            padding: 0px 0px 4px;
        }
    }

    &Logout {
        background: $white;
        color: $black;
        margin: 4px auto;
        padding: 6px 4px;
        width: 80px;
        min-width: 80px;
    }
}

.details {
    @extend %flex;

    flex: 1;

    &Title {
        font-size: 20px;
        font-weight: 600;
    }

    &Sidebar {
        padding: 20px;
        width: 20%;
        border-right: 1px solid $grey-30;

        &List {
            margin: 20px 0px;
            padding: 8px 0px;
            border-top: 1px solid $grey-30;

            &Item {
                margin: 8px 0px;
                padding: 8px 12px;
                border-radius: 8px;
                font-weight: 400;
                cursor: pointer;

                &:hover {
                    background: $blue-light;
                }
            }

            &Active {
                background: $blue-25;
            }
        }
    }

    &Docs {
        padding: 20px;
        width: 80%;
    }
}

.empty {
    @extend %flex-center;

    height: 320px;
    font-size: 18px;
    font-weight: 600;
}

.table {
    @extend %flex;

    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}
