@import '../../styles.module.scss';

.listData {
    flex: 1;

    &Text {
        display: flex;
        flex: 1;
        text-align: justify;
        margin-top: 8px;
        font-size: 16px;
    }

    &Item {
        margin-bottom: 12px;
        text-align: left;
        list-style: disc;

        &Select {
            height: 16px;
            width: 16px;
        }
    }

    &Input {
        min-height: 120px;
        height: fit-content;
        overflow-y: auto;
        width: 100%;
    }

    &Textbox {
        padding: 12px;
        border-color: #ccc;
    }
}

.icons {
    @extend %flex-center-vertical;

    &Pencil,
    &Accept,
    &Cancel {
        position: relative;
        width: 18px;
        height: 18px;
        cursor: pointer;
    }

    &Pencil {
        color: $grey;
    }

    &Accept {
        color: $softLimeGreen;
        margin-left: 12px;
    }

    &Cancel {
        color: $red;
    }
}

.diff {
    background: rgba(#8ee0b6, 0.25);
}

.list {
    padding: 0px 0px 0px 0px;

    &Text{
        padding: 8px;
        background: $white;
    }
}
