@import 'src/shared/styles/index.scss';

.feedback {
    position: relative;
    display: flex;
    width: 100%;
    justify-content: center;
    margin: 24px 0px;

    &Thumbsup,
    &Thumbsdown {
        position: relative;
        cursor: pointer;
        font-size: 20px;
        transition: transform 0.2s;
        will-change: scale;
    }

    &Thumbsup {
        margin-right: 20px;

        &:hover {
            .feedbackThumbsupHover {
                transition: opacity 1s;
                opacity: 1;
                top: -18px;
            }

            transform: scale(1.1);
            color: $white;
            background-color: rgb(156, 163, 175);
        }

        &Hover {
            color: $green;
            font-size: $font_size_14;
            opacity: 0;
            position: absolute;
            top: 0px;
            left: 4px;
            font-weight: 500;
        }
    }

    &Thumbsdown {
        &:hover {
            .feedbackThumbsdownHover {
                transition: opacity 1s;
                opacity: 1;
                top: -18px;
            }

            transform: scale(1.1);
            background-color: rgb(156, 163, 175);
        }

        &Hover {
            color: $warning;
            font-size: $font_size_14;
            opacity: 0;
            position: absolute;
            top: 0px;
            left: -4px;
            font-weight: 500;
        }
    }
}

.comment {
    background: $white;
    position: absolute;
    left: -200px;
    width: 316px;
    border-radius: 4px;

    &Icon {
        position: absolute;
        top: 4px;
        right: 8px;
        background: $black;
        color: $white;
        border-radius: 100%;
        font-size: 20px;
        transform: rotate(45deg);
        cursor: pointer;
    }
}
