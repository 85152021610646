@import '../../shared/styles/index.scss';

.tooltip {
    @extend %flex-center;

    &Title {
        margin-right: 2px;
        font-weight: 600;
    }

    &Base {
        position: relative;
        margin-top: 2px;
    }

    &Info {
        @extend %flex-center;
        width: 16px;
        height: 16px;
        cursor: pointer;

        &Icon {
            height: 100%;
            width: 100%;
        }

        &Cloud {
            @extend %flex-center;

            position: absolute;
            bottom: 152%;
            left: -100%;
            background-color: $black;
            border-radius: 4px;
            padding: 4px;
            min-width: 280px;
            width: fit-content;
            max-width: 360px;
            flex-wrap: wrap;
            visibility: hidden;
            opacity: 0;
            transition: opacity 0s ease-in-out;
            z-index: 9;
            border-radius: 12px;

            &Tip {
                position: absolute;
                top: 100%;
                left: 16px;
                border-left: 8px solid transparent;
                border-right: 8px solid transparent;
                border-top: 8px solid $black;
            }
        }

        &Desc {
            padding: 8px;
            font-size: 14px;
            text-align: left;
        }

        &:hover {
            ~ .tooltipInfoCloud {
                visibility: visible;
                opacity: 1;
            }
        }
    }
}
