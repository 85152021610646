$white: #fff;
$black: #000;
$blue: #8bb3e7;

$warning: #d2042d;
$red: #ff0000;
$red-10: #eb4511;

$grey: #808080;
$grey-30: #cccccc4d;
$grey-50: #cccccc80;
$grey-80: #cccccc;
$grey-90: #cccccce6;
$grey-95: #eeeeeee6;
$grey-100: #f6f6f6;

$darkBlue: #104891;
$blue-10: #2563eb;
$darkBlue-30: #045de9;
$darkBlue-50: #09c6f9;
$vivid-blue: #009ffd;
$blue-light: rgba($blue, 0.1);
$blue-15: rgba($blue, 0.15);
$blue-25: rgba($blue, 0.5);

$limeGreen: #05c46b;
$softLimeGreen: #67ec86;
$lightGreen: #94ebc2;
$green: #228b22;

$overlay-bg: rgba(0, 0, 0, 0.5);
$white-10: #f1f1f1;
// $body-background: #aee1f9;
// $body-backround2: #f6ebe6;
$body-background: #f7f7f7;

$light-purple: rgba(#ccccff, 0.1);

$border-color: rgba(75, 85, 99, 0.2);

;@import "sass-embedded-legacy-load-done:1";