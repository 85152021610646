@import 'src/shared/styles/index.scss';

.edit {
    height: 18px;
    width: 18px;
    cursor: pointer;
    margin: 0px 8px;
    transition: transform 0.4s ease-in-out;
    will-change: transform;

    &:hover {
        color: $white;
        transform: scale(1.1);
    }
}

.input {
    width: 30%;
    margin: 0px 16px 0px;
}

.org {
    position: absolute;
    
    transform: translateX(200%);

    &Icon {
        height: 18px;
        width: 18px;
        margin-right: 8px;
    }
}

;@import "sass-embedded-legacy-load-done:167";