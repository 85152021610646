@import 'src/shared/styles';

.section {
    background: $grey-30;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    min-height: calc(100vh - 40px);

    @media screen and (max-width: 620px) {
        padding: 16px;
    }
}
